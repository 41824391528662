import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { Column } from 'ag-grid-community'
import { getVendorOptions } from 'components/App/helpers/vendorHelpers'
import { DropdownOption } from '../../../../../models/app/DropdownOption.model'
import { useAgGridSignLibraryContext } from 'components/SignLibrary/context/agGridSignLibraryContext'
import { SignLibraryResponse } from '../../../../../models/signLibrary'
import { ContractCore } from '../../../../../models/contracts/ContractCore.model'
import SignTemplateResponse from '../../../../../models/signTemplates/SignTemplateResponse.model'

export interface Props {
  data: SignLibraryResponse
  value: string
  column: Column
}

const AutocompleteCellEditor = forwardRef(
  ({ data, value, column }: Props, ref) => {
    const { activeContracts, signTemplateOptions, getSignTemplatesByType } =
      useAgGridSignLibraryContext()!
    const [editValue, setEditValue] = useState(value)
    const [signSizeOptions, setSignSizeOptions] = useState<
      DropdownOption<string>[]
    >([])
    const [signDefinitionOptions, setSignDefinitionOptions] = useState<
      DropdownOption<string>[]
    >([])

    useEffect(() => {
      if (
        data.non_retail_item_info.sign_type !== null &&
        data.non_retail_item_info.sign_type !== ''
      ) {
        const filteredOptions = activeContracts
          .filter(
            (contract: ContractCore) =>
              contract.signType === data.non_retail_item_info.sign_type,
          )
          .map((contract: ContractCore, index: number) => {
            return new DropdownOption<string>({
              id: index,
              label: contract.signSize,
              value: contract.signSize,
            })
          })
        filteredOptions.push(
          new DropdownOption({
            id: 'CUSTOM',
            value: 'CUSTOM',
            label: 'CUSTOM',
          }),
        )
        const uniqueOptions = Array.from(
          new Map(
            filteredOptions.map((option) => [option.value, option]),
          ).values(),
        )
        setSignSizeOptions(uniqueOptions)
      }
    }, [data.non_retail_item_info.sign_type, activeContracts])

    useEffect(() => {
      if (
        data.non_retail_item_info.sign_type !== null &&
        data.non_retail_item_info.sign_type !== '' &&
        data.non_retail_item_info.sign_size !== null &&
        data.non_retail_item_info.sign_size !== ''
      ) {
        const filteredOptions = activeContracts
          .filter(
            (contract: ContractCore) =>
              contract.signType === data.non_retail_item_info.sign_type &&
              contract.signSize === data.non_retail_item_info.sign_size,
          )
          .map((contract: ContractCore) => {
            return new DropdownOption<string>({
              id: contract.signDefinition,
              label: contract.signDefinition,
              value: contract.signDefinition,
            })
          })
        if (data.non_retail_item_info.sign_size === 'CUSTOM') {
          filteredOptions.push(
            new DropdownOption<string>({
              id: 'CUSTOM',
              value: 'CUSTOM',
              label: 'CUSTOM',
            }),
          )
        }
        const uniqueOptions = Array.from(
          new Map(
            filteredOptions.map((option) => [option.value, option]),
          ).values(),
        )
        setSignDefinitionOptions(uniqueOptions)
      }
    }, [
      data.non_retail_item_info.sign_type,
      data.non_retail_item_info.sign_size,
      activeContracts,
    ])

    const handleOptions = (query: string) => {
      switch (column.getColId()) {
        case 'non_retail_item_info.sign_template_info.template_name':
          return signTemplateOptions.map((template: SignTemplateResponse) => ({
            value: template.name,
            label: template.name,
          }))
        case 'non_retail_item_info.sign_size':
          return signSizeOptions
        case 'non_retail_item_info.sign_definition':
          return signDefinitionOptions
        default:
          return getVendorOptions(query, editValue)
      }
    }

    const handleChange = (id: string, vendor: string) => setEditValue(vendor)

    useEffect(() => {
      if (
        column.getColId() ===
        'non_retail_item_info.sign_template_info.template_name'
      ) {
        getSignTemplatesByType(data.non_retail_item_info.sign_type!)
      }
    }, [data.non_retail_item_info.sign_type, column, getSignTemplatesByType])

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return editValue
        },
        isPopup() {
          return true
        },
      }
    })

    return (
      <div className="hc-pa-dense">
        <Grid.Container className="sem_StandardForm error_messaging_enabled wide-form">
          <Grid.Item xs={12}>
            <Autocomplete
              label={column.getColDef().headerName}
              options={(query: string) => handleOptions(query)}
              value={{
                value: editValue,
                label: editValue,
              }}
              onUpdate={(id: string, value: any) => {
                if (value) handleChange(id, value.value)
              }}
            />
          </Grid.Item>
        </Grid.Container>
      </div>
    )
  },
)

export default AutocompleteCellEditor
