import React, { useCallback, useContext, useEffect, useState } from 'react'
import { createContext } from 'react'
import { connect } from 'react-redux'
import { useProjectDetailsContext } from 'components/ProjectDetails/context/projectDetailsContext'
import axios from 'axios'
import { useEnv } from '@praxis/component-runtime-env'
import {
  SIGN_SERVICE_API_DOMAIN_URL,
  TOASTER_DEFAULTS,
  SIGN_WORKFLOW_STATUS,
  SIGN_STATUS,
} from 'components/App/constants/appConstants'
import { useToaster } from '@enterprise-ui/canvas-ui-react'
import SignResponse from '../../../../../models/signs/SignResponse.model'
import { DropdownOption } from '../../../../../models/app/DropdownOption.model'
import AddOnExpenses from '../../../../../models/addOnExpenses/AddOnExpenses.model'
import { useUserContext } from 'components/App/context/userContext'
import { RowNode, SelectionChangedEvent } from 'ag-grid-community'
import updateAddOnExpensesRequest from 'models/addOnExpenses/updateAddOnExpensesRequest.model'

type ContextType = {
  getAddOnExpenses: () => void
  addOnExpenses: AddOnExpenses[]
  printVendorOptions: DropdownOption<string>[]
  expensesModified: boolean
  setExpensesModified: (modified: boolean) => void
  updateExpenses: () => void
  modifiedExpensesIds: string[]
  setModifiedExpensesIds: Function
  modifiedExpenses: updateAddOnExpensesRequest
  setModifiedExpenses: Function
  isLoadingExpenses: boolean
  deleteExpense: (id: string) => void
  isCreatingExpense: boolean
  setIsCreatingExpense: (isCreatingExpense: boolean) => void
  createExpense: () => void
  onSelectionChange: (event: SelectionChangedEvent) => void
  setSelectedRows: Function
  selectedRows: AddOnExpenses[]
  isAnySignPricingMissing: boolean
  isPriceGatheringCompleted: () => boolean
}

export const AddOnContext = createContext<ContextType | undefined>(undefined)

type Props = {
  children: React.ReactNode
}

export const AddOnProviderComponent = ({ children }: Props) => {
  const env = useEnv()
  const makeToast = useToaster()
  const { currentProject, projectSignList } = useProjectDetailsContext()!
  const { userEmail, userPermissions, userCompany } = useUserContext()!

  const [addOnExpenses, setAddOnExpenses] = useState<AddOnExpenses[]>([])
  const [printVendorOptions, setPrintVendorOptions] = useState<
    DropdownOption<string>[]
  >([])
  const [expensesModified, setExpensesModified] = useState(false)
  const [isLoadingExpenses, setIsLoadingExpenses] = useState(false)
  const [modifiedExpenses, setModifiedExpenses] =
    useState<updateAddOnExpensesRequest>({
      update_add_on_expenses_request_dto: [],
      expenses_status: '',
    })
  const [modifiedExpensesIds, setModifiedExpensesIds] = useState<string[]>([])
  const [isCreatingExpense, setIsCreatingExpense] = useState(false)

  const [selectedRows, setSelectedRows] = useState<AddOnExpenses[]>([])

  const [isAnySignPricingMissing, setAnySignPricingMissing] = useState(false)

  useEffect(() => {
    if (projectSignList.length > 0) {
      const printVendorList = projectSignList.map((sign: SignResponse) => {
        return sign.printing.print_vendor
      })
      const printVendorSet = new Set(printVendorList)
      const dropdownOptions = Array.from(printVendorSet).map(
        (printVendor: string | undefined) => {
          return new DropdownOption<string>({
            id: printVendor,
            value: printVendor,
            label: printVendor,
          })
        },
      )
      const signPricingMissingList = projectSignList?.filter(
        (sign: SignResponse) =>
          !sign.vendor_produced &&
          !sign.pricing?.final_unit_price &&
          ![
            SIGN_WORKFLOW_STATUS.COMPLETE.toString(),
            SIGN_WORKFLOW_STATUS.SENT_TO_ESS.toString(),
            SIGN_WORKFLOW_STATUS.NONE.toString(),
            SIGN_WORKFLOW_STATUS.SENT_TO_TPS.toString(),
            SIGN_STATUS.CARRY_FORWARD.toString(),
          ].includes(sign.workflow?.status),
      )
      setAnySignPricingMissing(signPricingMissingList.length > 0)
      setPrintVendorOptions(dropdownOptions)
    }
  }, [projectSignList])

  const getAddOnExpenses = useCallback(async () => {
    setIsLoadingExpenses(true)
    try {
      const res = await axios.get(
        `${env.apiDomainUrl}${SIGN_SERVICE_API_DOMAIN_URL}/add_on_expenses/${currentProject.project_id}`,
      )
      const filteredData =
        userPermissions.isPrintVendor ||
        userPermissions.isKitVendor ||
        userPermissions.isSAPVendor ||
        userPermissions.isAgencySeparator ||
        userPermissions.isProductVendorOrCatMan ||
        userPermissions.isTPSVendor
          ? res.data.filter(
              (expense: AddOnExpenses) => expense.print_vendor === userCompany,
            )
          : res.data
      setAddOnExpenses(filteredData)
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Get Add-On Expenses',
        message: err?.response?.data?.message,
      })
    }
    setIsLoadingExpenses(false)
  }, [
    currentProject.project_id,
    env.apiDomainUrl,
    makeToast,
    userCompany,
    userPermissions,
  ])

  const onSelectionChange = (event: SelectionChangedEvent) => {
    if (event.api.getSelectedNodes().length > 0) {
      setSelectedRows(
        event.api
          .getSelectedNodes()
          .map((node: RowNode) => new AddOnExpenses(node.data)),
      )
    } else {
      setSelectedRows([])
    }
  }
  const updateExpenses = useCallback(async () => {
    setIsLoadingExpenses(true)
    try {
      await axios.put(
        `${env.apiDomainUrl}${SIGN_SERVICE_API_DOMAIN_URL}/add_on_expenses/${currentProject.project_id}`,
        modifiedExpenses,
      )
      setModifiedExpenses({
        update_add_on_expenses_request_dto: [],
        expenses_status: '',
      })
      setModifiedExpensesIds([])
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Add-On Expenses Updated',
        message: 'Successfully updated add-on expenses',
      })
      await getAddOnExpenses()
    } catch (err: any) {
      makeToast({
        type: 'error',
        heading: 'Failed to Update Expenses',
        message: err?.response?.data?.message,
      })
    }
    setIsLoadingExpenses(false)
  }, [
    currentProject.project_id,
    env.apiDomainUrl,
    makeToast,
    modifiedExpenses,
    getAddOnExpenses,
  ])

  const deleteExpense = useCallback(
    async (id: string) => {
      setIsLoadingExpenses(true)
      try {
        await axios.delete(
          `${env.apiDomainUrl}${SIGN_SERVICE_API_DOMAIN_URL}/add_on_expenses/${id}`,
        )
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'success',
          heading: 'Add-On Expense Deleted',
          message: 'Successfully deleted add-on expense',
        })
        await getAddOnExpenses()
      } catch (err: any) {
        makeToast({
          ...TOASTER_DEFAULTS,
          type: 'error',
          heading: 'Failed to Delete Add-On Expense',
          message: err?.response?.data?.message,
        })
      }
      setModifiedExpensesIds([])
      setIsLoadingExpenses(false)
    },
    [env.apiDomainUrl, makeToast],
  )

  const isPriceGatheringCompleted = (): boolean => {
    const pgMilestone = 'Price Gathering Complete'
    return currentProject.milestones.some(
      (milestone) =>
        milestone.name === pgMilestone && milestone.status === 'Completed',
    )
  }

  const createExpense = useCallback(async () => {
    try {
      await axios.post(
        `${env.apiDomainUrl}${SIGN_SERVICE_API_DOMAIN_URL}/add_on_expenses`,
        modifiedExpenses?.update_add_on_expenses_request_dto.map((expense) => ({
          project_id: currentProject.project_id,
          sap_project_id: currentProject.sap_project_id,
          print_vendor: expense.print_vendor,
          expenses_type: expense.expenses_type,
          cost: expense.cost,
          vendor_notes: expense.vendor_notes,
          created_by: userEmail,
          updated_by: userEmail,
        })),
      )
      setModifiedExpenses({
        update_add_on_expenses_request_dto: [],
        expenses_status: '',
      })
      setModifiedExpensesIds([])
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'success',
        heading: 'Add-On Expense Created',
        message: 'Successfully created add-on expense',
      })
      setIsCreatingExpense(false)
      await getAddOnExpenses()
    } catch (err: any) {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'Failed to Create Add-On Expense',
        message: err?.response?.data?.message,
      })
    }
  }, [
    currentProject.project_id,
    currentProject.sap_project_id,
    env.apiDomainUrl,
    makeToast,
    modifiedExpenses,
    userEmail,
    getAddOnExpenses,
  ])

  return (
    <AddOnContext.Provider
      value={{
        getAddOnExpenses,
        printVendorOptions,
        expensesModified,
        setExpensesModified,
        updateExpenses,
        modifiedExpensesIds,
        setModifiedExpensesIds,
        modifiedExpenses,
        setModifiedExpenses,
        isLoadingExpenses,
        addOnExpenses,
        deleteExpense,
        isCreatingExpense,
        setIsCreatingExpense,
        createExpense,
        setSelectedRows,
        onSelectionChange,
        selectedRows,
        isAnySignPricingMissing,
        isPriceGatheringCompleted,
      }}
    >
      {children}
    </AddOnContext.Provider>
  )
}

export const useAddOnContext = () => useContext(AddOnContext)

export const AddOnProvider = connect(null, null)(AddOnProviderComponent)
